import React from "react";

export default ({ color }) => (
  <svg width="70" height="35" viewBox="0 0 70 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 34V60.25C4 62.665 5.96438 64.625 8.375 64.625H30.25V34H4Z" fill={color} />
    <path d="M39 34V64.625H60.875C63.29 64.625 65.25 62.665 65.25 60.25V34H39Z" fill={color} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35 18.001V17.417H34.8418C35.5332 12.2412 30.502 4.49316 28.6074 2.59082C25.1777 -0.858398 19.2012 -0.858398 15.7754 2.59082C12.25 6.1377 12.25 11.9131 15.7715 15.4619C16.3711 16.0654 17.2246 16.7393 18.2168 17.417L20 18.001H35ZM30.3672 17.2725C30.3672 17.2725 30.1836 17.417 29.5566 17.417C26.5352 17.417 20.7637 14.2764 18.8789 12.376C17.0449 10.5303 17.0449 7.52051 18.8789 5.6748C19.7656 4.78223 20.9434 4.29199 22.1934 4.29199C23.4414 4.29199 24.6172 4.78223 25.5059 5.6748C28.4551 8.63965 31.3867 16.2178 30.3672 17.2725Z" fill="#73A77B" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35 18H49.8691L51.6523 17.416C52.6445 16.7383 53.498 16.0645 54.0918 15.4609C57.623 11.9121 57.623 6.13672 54.0918 2.58984C50.6621 -0.867187 44.6855 -0.859374 41.2617 2.58984C39.5801 4.27344 35.4414 10.541 35 15.5469V17.1836C35.0078 17.2617 35.0176 17.3398 35.0273 17.416H35V18ZM40.3066 17.416C39.6855 17.416 39.502 17.2754 39.502 17.2715C38.4824 16.2168 41.4141 8.63867 44.3633 5.67383C46.125 3.89258 49.2109 3.88477 50.9902 5.67383C52.8281 7.51953 52.8281 10.5293 50.9902 12.375C49.1055 14.2754 43.334 17.416 40.3066 17.416Z" fill="#F76E9C" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.296 18C29.3853 18.0049 29.4725 18.0074 29.5575 18.0074C29.643 18.0074 29.7203 18.0047 29.79 18H29.296ZM34.8435 18C34.8432 18.0025 34.8428 18.0049 34.8425 18.0074H35.1575C35.1572 18.0049 35.1568 18.0025 35.1565 18H34.8435ZM40.2373 18C40.3666 18.0307 40.4555 18.0612 40.5 18.0916L4.375 18.0074C1.96437 18.0074 0 19.9717 0 22.3824V28.9449C0 30.1524 0.98 31.1324 2.1875 31.1324H30.625V22.3824H39.375V31.1324H67.8125C69.02 31.1324 70 30.1524 70 28.9449V22.3824C70 19.9717 68.04 18.0074 65.625 18.0074L40.5 18.0916C40.5445 18.0612 40.6113 18.0307 40.6984 18H40.2373Z" fill={color} />
  </svg>

);
